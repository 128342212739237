
import React from 'react';

export const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
        <p>&copy; {currentYear} Dow Labs. All rights reserved.
        </p>
        <p>
        <a href="/privacy" className="footer-link">Privacy Policy</a> | 
          <a href="/terms-of-service" className="footer-link">Terms of Service</a> | 
          support@getkaiapp.com
        </p>
      </footer>
  )
}