import React from 'react';


import './LandingPage.css';

import IPhoneMock from './components/PhoneMock';
import DownloadIconContainer from './components/DownloadIconContainer';
import { Footer } from './components/footer';

const LandingPage: React.FC = () => {

  const features = [
    { name: 'Home Screen', imageUrl: `${process.env.PUBLIC_URL}/mocks/home.jpg` },
    { name: 'Feed Screen', imageUrl: `${process.env.PUBLIC_URL}/mocks/feed.jpg` },
    { name: 'Events Screen', imageUrl: `${process.env.PUBLIC_URL}/mocks/events.jpg` },
    { name: 'Media Manage Screen', imageUrl: `${process.env.PUBLIC_URL}/mocks/media.jpg` },
  ];

  return (
    <div className="container">
      <div className="content">
        <h1 className="title">Welcome to Kai Social</h1>
        <p className="subtitle">Explore, Connect, and Share with the LGBTQ+ Community</p>
        
        <div className="feature-container">
          {features.map((feature, index) => (
            <IPhoneMock key={index} name={feature.name} imageUrl={feature.imageUrl} />
          ))}
        </div>
        <DownloadIconContainer />
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;