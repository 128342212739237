import React from 'react';
import { AppStoreButton, GooglePlayButton} from "react-mobile-app-button";

const DownloadIconContainer: React.FC = (): JSX.Element => {
  return (
    <div className="signup-container">
      <GooglePlayButton
        url={"https://play.google.com/store/apps/details?id=com.DowLabs.KaiSocial"}
        theme={"dark"}
        className={"custom-style"}
      />
      <AppStoreButton
        url={"https://apps.apple.com/app/kai-social/id6479544529"}
        theme={"light"}
        className={"custom-style"}
      />
    </div>
  );
};

export default DownloadIconContainer;