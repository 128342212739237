import React from 'react';

import { Footer } from './components/footer';

// Custom CSS styles
const styles = {
  container: {
    maxWidth: '42rem', // 2xl in Tailwind
    margin: '0 auto',
    padding: '1.5rem',
    backgroundColor: 'white',
    minHeight: '100vh'
  },
  header: {
    marginBottom: '2rem'
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '700',
    color: '#111827' // gray-900
  },
  subtitle: {
    marginTop: '0.5rem',
    color: '#4B5563' // gray-600
  },
  section: {
    marginBottom: '2rem'
  },
  stepContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '0.75rem',
    marginBottom: '1rem'
  },
  stepNumber: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '9999px',
    backgroundColor: '#DBEAFE', // blue-100
    color: '#2563EB', // blue-600
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600',
    flexShrink: 0
  },
  stepText: {
    color: '#374151' // gray-700
  },
  infoBox: {
    padding: '1.5rem',
    borderRadius: '0.5rem',
    marginBottom: '2rem'
  },
  infoBoxGray: {
    backgroundColor: '#F9FAFB' // gray-50
  },
  infoBoxYellow: {
    backgroundColor: '#FFFBEB' // yellow-50
  },
  list: {
    marginTop: '0.5rem',
    color: '#374151' // gray-700
  },
  listItem: {
    marginBottom: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem'
  },
  link: {
    color: '#2563EB', // blue-600
    textDecoration: 'none'
  },
  helpSection: {
    borderTop: '1px solid #E5E7EB', // gray-200
    paddingTop: '2rem'
  }
};

const AccountDeletionPage = (): JSX.Element => {
  return (
    <div style={styles.container}>
      {/* Header */}
      <div style={styles.header}>
        <h1 style={styles.title}>Kai Social by Dow Labs, LLC. </h1>
        <p style={styles.subtitle}>
          Learn how to delete your account and associated data. 
        </p>
      </div>

      {/* Main Content */}
      <div>
        {/* In-App Method */}
        <section style={styles.section}>
          <h2 style={styles.title}>Delete Account using the App</h2>
          <div>
            <div style={styles.stepContainer}>
              <div style={styles.stepNumber}>1</div>
              <div style={styles.stepText}>
                Open the App on your device
              </div>
            </div>

            <div style={styles.stepContainer}>
              <div style={styles.stepNumber}>2</div>
              <div style={styles.stepText}>
                Go to Account → Settings
              </div>
            </div>

            <div style={styles.stepContainer}>
              <div style={styles.stepNumber}>3</div>
              <div style={styles.stepText}>
                Tap on &quot;Delete Account&quot; under Account Management
              </div>
            </div>

            <div style={styles.stepContainer}>
              <div style={styles.stepNumber}>4</div>
              <div style={styles.stepText}>
                Follow the instructions to confirm account deletion
              </div>
            </div>
          </div>
        </section>

        {/* What Gets Deleted */}
        <section style={{...styles.infoBox, ...styles.infoBoxGray}}>
          <h2 style={styles.title}>What Gets Deleted</h2>
          <ul style={styles.list}>
            <li style={styles.listItem}>• All personal information</li>
            <li style={styles.listItem}>• User preferences and settings</li>
            <li style={styles.listItem}>• Activity history</li>
            <li style={styles.listItem}>• Content you&apos;ve created</li>
          </ul>
        </section>

        {/* Important Notes */}
        <section style={{...styles.infoBox, ...styles.infoBoxYellow}}>
          <h2 style={styles.title}>Important Information</h2>
          <ul style={styles.list}>
            <li style={styles.listItem}>• Account deletion is permanent and cannot be undone</li>
            <li style={styles.listItem}>• Deletion process may take up to 30 days to complete</li>
          </ul>
        </section>

        {/* Help Section */}
        <section style={styles.helpSection}>
          <h2 style={styles.title}>Need Help?</h2>
          <p style={styles.stepText}>
            If you&apos;re having trouble deleting your account or have any questions, 
            please contact our support team at{' '}
            <span style={styles.link}>support@getkaiapp.com</span>
          </p>
        </section>

      </div>
      <Footer />
    </div>
  );
};

export default AccountDeletionPage;